import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CachedIcon from '@mui/icons-material/Cached';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { visuallyHidden } from '@mui/utils';
import { animateScroll } from 'react-scroll';
import { ResultsContext } from '../context/ResultsContext';
import QuestionsInsightsCards from './QuestionsInsightsCards';
import { UsersContext } from '../context/UsersContext';
import { getQuestionInsights } from '../shared/tools';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'question',
    numeric: false,
    disablePadding: true,
    label: 'Questions',
  },
  {
    id: 'successPercentage',
    numeric: true,
    disablePadding: false,
    label: 'Correct',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all results',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({selected, setSelected, setQuestionsInsightsToDisplay, results, refetchResultsHandler}) {

  function scrollToTableEnd() {
    const heights = Array.from(document.querySelectorAll('.insights-heading, #table-box')).map(e => e.offsetHeight);
    const totalHeight = heights.reduce((acc, h) => acc + h, 0);
  
    animateScroll.scrollTo(totalHeight, {
      duration: 1000,
      delay: 0,
    });
  }
  
  const handleOpen = (resultsSelected) => {
    setQuestionsInsightsToDisplay(resultsSelected)
    scrollToTableEnd()
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selected.length > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {selected.length > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selected.length} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Questions Insights
        </Typography>
      )}

      {selected.length > 0 ? (
        <>
          <Tooltip title='Clear'>
            <IconButton onClick={() => {
              setSelected([])
              setQuestionsInsightsToDisplay([])
            }}>
              <HighlightOffIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title='Open'>
            <IconButton onClick={() => {
              handleOpen(selected)
            }}>
              <OpenInFullIcon/>
            </IconButton>
          </Tooltip>
        </>

      ) : (
        <Tooltip title="Reload">
          <IconButton onClick={() => {
              refetchResultsHandler()
            }}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export default function QuestionsInsightsTable() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('successPercentage');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [questionsInsightsToDisplay, setQuestionsInsightsToDisplay] = useState([])

  const { results, deleteResultsHandler, refetchResultsHandler } = useContext(ResultsContext);
  const { users } = useContext(UsersContext)

  const adminUsers = users.filter(user => user.admin)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const rows = getQuestionInsights(results, adminUsers);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows;
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.findIndex(selectedRow => {
      return selectedRow.id === row.id;
    });
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => {
    for(let itemSelected of selected) {
      if(itemSelected.id === row.id) return true
    }
    return false
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
      <>
        <Box sx={{ width: '100%' }} id="table-box">
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar 
              selected={selected}
              setSelected={setSelected}
              results={results}
              setQuestionsInsightsToDisplay={setQuestionsInsightsToDisplay}
              deleteResultsHandler={deleteResultsHandler}
              refetchResultsHandler={refetchResultsHandler}
            />
            <TableContainer>
              <Table
                sx={{ minWidth: 350 }}
                aria-labelledby="tableTitle"
                size={'small'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
  
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.question}
                          </TableCell>
                          <TableCell 
                            align="right"
                            className={
                              row.successPercentage >= 80 ?
                              "green" :
                              row.successPercentage >= 50 ?
                              "yellow" :
                              "red" 
                            }>{row.successPercentage}%
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (33) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      <QuestionsInsightsCards questionsInsightsToDisplay={questionsInsightsToDisplay}/>
    </>
  );
}