import React from 'react';
import NavBar from '../Components/NavBar';

const Layout =({children}) =>{
    return(
      <>
        <main>{children}</main>
        <div className='bottom-navbar'>
          <NavBar/>
        </div>
      </>
    )
}

export default Layout;