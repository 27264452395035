export const Pictures = [
  {
    label: "Parker_401",
    file: "frame-1.png",
    type: "frame"
  },
  {
    label: "Aris_081",
    file: "frame-2.png",
    type: "frame"
  },
  {
    label: "Brooks_100",
    file: "frame-3.png",
    type: "frame"
  },
  {
    label: "Allison_042",
    file: "frame-4.png",
    type: "frame"
  },
  {
    label: "Thomas_099",
    file: "frame-5.png",
    type: "frame"
  },
  {
    label: "Serge_095",
    file: "frame-6.png",
    type: "frame"
  },
  {
    label: "Leon_316",
    file: "frame-7.png",
    type: "frame"
  },
  {
    label: "Max_049",
    file: "frame-8.png",
    type: "frame"
  },
  {
    label: "Cape_Black",
    file: "frame-9.png",
    type: "frame"
  },
  {
    label: "Haskin_081",
    file: "frame-10.png",
    type: "frame"
  },
  {
    label: "Kane_081",
    file: "frame-11.png",
    type: "frame"
  },
  {
    label: "Penny_081",
    file: "frame-12.png",
    type: "frame"
  },
  {
    label: "Taku_055",
    file: "frame-13.png",
    type: "frame"
  },
  {
    label: "Odette_071",
    file: "frame-14.png",
    type: "frame"
  },
  {
    label: "Logan_262",
    file: "frame-15.png",
    type: "frame"
  },
  {
    label: "Benny_194",
    file: "frame-16.png",
    type: "frame"
  },
  {
    label: "Grant_195",
    file: "frame-17.png",
    type: "frame"
  },
]