import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QuizOW } from './Pages/QuizOW';
import { Results } from './Pages/Results';
import { Login } from './Pages/Login';
import Layout from './Pages/Layout';
import Quiz from './QuizLib/Quiz';
import { ManageUsers } from './Pages/ManageUsers';
import { Questions } from './Pages/Questions';
import { QuestionsProvider } from './context/QuestionsContext';
import { ResultsProvider } from './context/ResultsContext';
import { AuthProvider } from './context/AuthContext';
import { UsersProvider } from './context/UsersContext';
import { NotFound } from './Pages/NotFound';
import { Insights } from './Pages/Insights';
import { Leaderboard } from './Pages/Leaderboard';
import { CategoriesProvider } from './context/CategoriesContext';

function App() {
  return (
    <QuestionsProvider>
      <ResultsProvider>
        <AuthProvider>
          <UsersProvider>
            <CategoriesProvider>
              <BrowserRouter>
                <Layout>
                  <Routes>
                    <Route path="/">
                      <Route index element={<QuizOW/>} />
                      <Route path="results" element={<Results />} />
                      <Route path="login" element={<Login />} />
                      <Route path="quiz" element={<Quiz/>} />
                      <Route path="users" element={<ManageUsers />} />
                      <Route path="questions" element={<Questions />} />
                      <Route path="insights" element={<Insights/>} />
                      <Route path="leaderboard" element={<Leaderboard/>} />
                      <Route path="*" element={<NotFound/>} />
                    </Route>
                  </Routes>
                </Layout>
              </BrowserRouter>
            </CategoriesProvider>
          </UsersProvider>
        </AuthProvider>
      </ResultsProvider>
    </QuestionsProvider>
  );
}

export default App;
