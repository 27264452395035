import { Typography, TextField, FormGroup, Button } from "@mui/material"
import { useState, useContext } from "react";
import { CategoriesContext } from "../context/CategoriesContext";

export default function CategoryForm ({categoryToBeEdited, handleClickCancel}) {  
  const [category, setCategory] = useState(categoryToBeEdited?.name || "");
  const { createCategoryHandler, updateCategoryHandler } = useContext(CategoriesContext);


  const onClickSave = (closeModal = true) => {
    const result = {
      name: category
    }

    if(categoryToBeEdited && categoryToBeEdited.name !== category) {
      updateCategoryHandler(categoryToBeEdited.id, { name: result.name })
    }

    if(!categoryToBeEdited) {
      createCategoryHandler(result)
    }

    if(closeModal){
      handleClickCancel()
    } else {
      setCategory("");
    }
  }

  const onClickCancel = () => {
    handleClickCancel()
  }

  const isSaveDisabled = category.length < 2 || (categoryToBeEdited && categoryToBeEdited.name === category);

  return(
  <div className="question-container">
      <Typography variant="h4">
        {categoryToBeEdited ? "Edit" : "Add"} Category
      </Typography>
        <FormGroup className="category-form">
            <TextField 
              className="category-input"
              label="Category"
              variant="standard"
              multiline
              required
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
            <div className="category-modal-btn">
            <Button 
                variant="outlined" 
                onClick={onClickCancel} 
              >
                Cancel
              </Button>
              {
                !categoryToBeEdited &&
                <Button 
                  variant="outlined" 
                  onClick={() => onClickSave(false)}
                  disabled={isSaveDisabled}
                >
                  Save
               </Button>
              }

              <Button 
                variant="outlined" 
                onClick={onClickSave}
                disabled={isSaveDisabled}
              >
                Save & close
              </Button>
            </div>
            </FormGroup>
          </div>
  )
}