import { createContext, useState, useEffect } from 'react';
import { deleteUser, fetchUsers, insertUser } from '../shared/tools';

export const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  

  useEffect(() => {
    const loadUsers = async () => {
      const response = await fetchUsers();
      setUsers(response.resources);
      setIsLoading(false);
    };
    loadUsers();
  }, []);

  
  const createUserHandler = async (newUser) => {
    insertUser(newUser).then(() => {
      setUsers([...users, newUser])
    }).catch((e) => console.log(e))
  };

  const deleteUsersHandler = async (userToBeDeleted) => {
    deleteUser(userToBeDeleted).then(code => {
      if(code === 204)
        setUsers(users.filter(user => user.id !== userToBeDeleted.id));
      })
  };

  return (
    <UsersContext.Provider
      value={{ 
        users,
        isLoading,
        createUserHandler,
        deleteUsersHandler,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};