import { createContext, useState, useEffect } from 'react';
import { deleteResults, fetchResults, insertResult } from '../shared/tools';

export const ResultsContext = createContext();

export const ResultsProvider = ({ children }) => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  

  useEffect(() => {
    const loadResults = async () => {
      const response = await fetchResults();
      setResults(response.resources);
      setIsLoading(false);
    };
    loadResults();
  }, []);

  
  const createResultHandler = async (newResult) => {
    insertResult(newResult).then(() => {
      setResults([...results, newResult])
    }).catch((e) => console.log(e))
  };

  const refetchResultsHandler = async () => {
    setIsLoading(true);
    const response = await fetchResults();
    setResults(response.resources);
    setIsLoading(false);
  };

  const deleteResultsHandler = async (resultsToBeDeleted) => {
    let newResults = [...results];
    for(let resultToBeDeleted of resultsToBeDeleted) {
      const code = await deleteResults(resultToBeDeleted);
      if(code === 204) {
        newResults = newResults.filter(r => r.id !== resultToBeDeleted.id);
        setResults(newResults)
      }
    }
  };

  return (
    <ResultsContext.Provider
      value={{ 
        results,
        isLoading,
        deleteResultsHandler,
        refetchResultsHandler,
        createResultHandler
      }}
    >
      {children}
    </ResultsContext.Provider>
  );
};