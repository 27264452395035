const { CosmosClient } = require("@azure/cosmos");
const endpoint = process.env.REACT_APP_EP;
const key = process.env.REACT_APP_KY;
const db = process.env.REACT_APP_DB;
const client = new CosmosClient({ endpoint, key });

async function connectDB() {
  const { database } = await client.databases.createIfNotExists({ id: db });
  return database
}

export async function fetchResults() {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "employees-responses" });
  return await container.items.readAll().fetchAll()
}

export const insertResult = async (result) => {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "employees-responses" });

  const { resource } = await container.items.create(result);
  console.log(`'${resource.userName}' result inserted`);
}

export const deleteResults = async (rowsToBeDeleted) => {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "employees-responses" });

  const { statusCode } = await container.item(rowsToBeDeleted.id, rowsToBeDeleted.userName).delete();
  console.log(`${rowsToBeDeleted.userName} ${statusCode===204 ? `test result deleted` : `Test result not deleted`}`);

  return statusCode;
}

export async function fetchUsers() {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "employees-users" });
  return await container.items.readAll().fetchAll()
}

export const insertUser = async (user) => {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "employees-users" });
  const { resource } = await container.items.create(user);
  console.log(`'${resource.name}' inserted in users db`);
}

export const deleteUser = async (userToBeDeleted) => {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "employees-users" });

  const { statusCode } = await container.item(userToBeDeleted.id, userToBeDeleted.name).delete();
  console.log(`${userToBeDeleted.name} ${statusCode===204 ? `user deleted` : `user not deleted`}`);
  return statusCode;
}

export async function fetchQuestions() {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "quiz-questions" });
  return await container.items.readAll().fetchAll()
}

export const insertQuestion = async (question) => {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "quiz-questions" });
  const { resource } = await container.items.create(question);
  console.log(`'${resource.question}' inserted in questions db`);
}

export const deleteQuestion = async (questionToBeDeleted) => {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "quiz-questions" });

  const { statusCode } = await container.item(questionToBeDeleted.id, questionToBeDeleted.id).delete();
  console.log(`${questionToBeDeleted.question} ${statusCode===204 ? `deleted` : `not deleted`}`);
  return statusCode;
}

export async function updateQuestion(questionId, updates) {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "quiz-questions" });

  try {
    const { resource } = 
      await container.item(questionId, questionId).read();

    const updatedResource = {
      ...resource,
      ...updates
    };

    const { resource: updatedQuestion } = await container
      .item(questionId)
      .replace(updatedResource);

    console.log(`Question ${updatedQuestion.question} was updated successfully`, updates);

    return updatedQuestion;
  } catch (err) {
    console.error(err);
  }
}

export const insertCategory = async (category) => {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "questions-categories" });
  const { resource } = await container.items.create(category);
  console.log(`'${resource.name}' inserted in categories db`);
}

export async function fetchCategories() {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "questions-categories" });
  return await container.items.readAll().fetchAll()
}

export const deleteCategory = async (categoryToBeDeleted) => {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "questions-categories" });

  const { statusCode } = await container.item(categoryToBeDeleted.id, categoryToBeDeleted.id).delete();
  console.log(`${categoryToBeDeleted.name} ${statusCode===204 ? `deleted` : `not deleted`}`);
  return statusCode;
}

export async function updateCategory(categoryId, updates) {
  const database = await connectDB();
  const { container } = await database.containers.createIfNotExists({ id: "questions-categories" });

  try {
    const { resource } = 
      await container.item(categoryId, categoryId).read();

    const updatedResource = {
      ...resource,
      ...updates
    };

    const { resource: updatedCategory } = await container
      .item(categoryId)
      .replace(updatedResource);

    console.log(`Category ${updatedCategory.name} was updated successfully`, updates);

    return updatedCategory;
  } catch (err) {
    console.error(err);
  }
}

export function getTimeFromEpoch(epoc) {
  const date = new Date(epoc * 1000);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().substr(-2);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export async function getUserInfo() {
  try {
    const response = await fetch('/.auth/me');
    const payload = await response.json();
    const { clientPrincipal } = payload;
    return clientPrincipal;
  } catch (error) {
    console.error('No profile could be found');
    return undefined;
  }
}

export function getUserInsights(quizResults, adminUsers) {
  const userInsights = {};

  // Loop through each quiz result
  quizResults.forEach((result) => {
    const userName = result.userName;

    // Don't use results from admins
    if (adminUsers.find(user => user.name === userName)) {
      return;
    }

    // Initialize user insight object if it does not exist
    if (!userInsights[userName]) {
      userInsights[userName] = {
        id: result.id,
        questionsCorrect: [],
        timesCorrect: 0,
        questionsIncorrect: [],
        timesIncorrect: 0,
        totalResults: 0,
      };
    }

    userInsights[userName].submissionTime = result._ts
    userInsights[userName].totalResults++;
    // Loop through each question in the result
    result.questions.forEach((question) => {
      // Check if the user's input matches the correct answer
      let isCorrect = false;
      if (typeof question.correctAnswer === "string") {
        isCorrect = question.correctAnswer === result.userInput[question.questionIndex - 1].toString();
      } else {
        const userInput = result.userInput[question.questionIndex - 1];
        if (Array.isArray(userInput)) {
          userInput.sort();
        }
        isCorrect = JSON.stringify(question.correctAnswer.sort()) === JSON.stringify(userInput);
      }

      // Add question to correctQuestions or incorrectQuestions array based on whether the answer is correct
      if (isCorrect) {
        userInsights[userName].timesCorrect++;
        userInsights[userName].questionsCorrect.push({question: question.question, questionPic: question.questionPic });
      } else {
        userInsights[userName].timesIncorrect++;
        userInsights[userName].questionsIncorrect.push({question: question.question, questionPic: question.questionPic });
      }
    });
  });

  // Return userInsights as an array
  return Object.entries(userInsights).map(([userName, insights]) => {
    return {
      id: insights.id,
      userName: userName,
      questionsCorrect: insights.questionsCorrect,
      questionsIncorrect: insights.questionsIncorrect,
      submissionTime: insights.submissionTime,
      timesCorrect: insights.timesCorrect,
      timesIncorrect: insights.timesIncorrect,
      successPercentage: getPercentage(insights.timesCorrect, insights.timesCorrect + insights.timesIncorrect),
      totalResults: insights.totalResults,
    };
  });
}

export function getQuestionInsights(quizResults, adminUsers) {
  const questionsInsights = {};

  // Loop through each quiz result
  quizResults.forEach((result) => {

    if (adminUsers.find(user => user.name === result.userName)) {
      return;
    }
    // Loop through each question in the result
    result.questions.forEach((question) => {
      // If the question has not been added to the questionsInsights object yet, initialize it
      if (!questionsInsights[question.id]) {
        questionsInsights[question.id] = {
          id: question.id,
          question: question.question,
          questionPic: question.questionPic || null,
          timesCorrect: 0,
          correctUsers: [],
          timesIncorrect: 0,
          incorrectUsers: [],
          successPercentage: 0,
        };
      }

      // Check if the user's input matches the correct answer
      let isCorrect = false;
      if (typeof question.correctAnswer === "string") {
        isCorrect = question.correctAnswer === result.userInput[question.questionIndex - 1].toString();
      } else {
        isCorrect = JSON.stringify(question.correctAnswer.sort()) === JSON.stringify(Array.isArray(result.userInput[question.questionIndex - 1]) ? result.userInput[question.questionIndex - 1].sort() : result.userInput[question.questionIndex - 1]);

      }

      // If the answer is correct, increment the timesCorrect counter and add the user to the correctUsers array
      // Otherwise, increment the timesIncorrect counter and add the user to the incorrectUsers array

      if (isCorrect) {
        questionsInsights[question.id].timesCorrect++;
        questionsInsights[question.id].correctUsers.push(result.userName);
      } else {
        questionsInsights[question.id].timesIncorrect++;
        questionsInsights[question.id].incorrectUsers.push(result.userName);
      }

      questionsInsights[question.id].successPercentage = getPercentage(questionsInsights[question.id].timesCorrect, questionsInsights[question.id].timesCorrect + questionsInsights[question.id].timesIncorrect)
    });
  });

  // Convert the questionsInsights object into an array and return it
  return Object.values(questionsInsights);
}

export function getTopFiveUsers(users) {
  const sortedUsers = users.sort((a, b) => {
    if (a.questionsCorrect.length > b.questionsCorrect.length) {
      return -1;
    } else if (a.questionsCorrect.length < b.questionsCorrect.length) {
      return 1;
    } else {
      return a.submissionTime - b.submissionTime;
    }
  });

  return sortedUsers.slice(0, 5);
}

export function getTopFiveUsersThisWeek(users) {
  const now = Date.now() / 1000; // current time in seconds since epoch
  const today = new Date(now * 1000);
  const todayDayOfWeek = today.getDay();
  const daysSinceMonday = todayDayOfWeek === 0 ? 6 : todayDayOfWeek - 1;
  const monday = new Date((now - (daysSinceMonday * 24 * 60 * 60)) * 1000);
  const sunday = new Date((now + ((6 - daysSinceMonday) * 24 * 60 * 60)) * 1000);

  const sortedUsers = users
    .filter(user => user.submissionTime >= monday.getTime() / 1000 && user.submissionTime <= sunday.getTime() / 1000)
    .sort((a, b) => {
      if (a.questionsCorrect.length > b.questionsCorrect.length) {
        return -1;
      } else if (a.questionsCorrect.length < b.questionsCorrect.length) {
        return 1;
      } else {
        return a.submissionTime - b.submissionTime;
      }
    });

  return sortedUsers.slice(0, 5);
}

export function getPercentage(value, total) {
  return Math.round(value / total * 100)
}

export function addAnswerInsightsToQuestions(questions, results, adminUsers) {
  return questions.map((question) => {
    const questionCopy = { ...question };
    let usersCorrect = [];
    let usersIncorrect = [];
    let timesAnswered = 0;
    let timesCorrect = 0;
    let timesIncorrect = 0;

    results.forEach((result) => {
      if (adminUsers.find(user => user.name === result.userName)) {
        return;
      }
      result.questions.forEach((resultQuestion, index) => {
        if (resultQuestion.id === questionCopy.id) {
          timesAnswered++;
          const userAnswer = result.userInput[index];
          if (typeof question.correctAnswer === "string") {
            if (userAnswer.toString() === question.correctAnswer) {
              usersCorrect.push(result.userName);
              timesCorrect++;
            } else {
              usersIncorrect.push(result.userName);
              timesIncorrect++;
            }
          } else {
            const isCorrect = JSON.stringify(question.correctAnswer.sort()) === JSON.stringify(Array.isArray(userAnswer) ? userAnswer.sort() : [userAnswer]);
            if (isCorrect) {
              usersCorrect.push(result.userName);
              timesCorrect++;
            } else {
              usersIncorrect.push(result.userName);
              timesIncorrect++;
            }
          }
        }
      });
    });

    questionCopy.timesAnswered = timesAnswered;
    questionCopy.timesCorrect = timesCorrect;
    questionCopy.timesIncorrect = timesIncorrect;
    questionCopy.usersCorrect = usersCorrect;
    questionCopy.usersIncorrect = usersIncorrect;

    return questionCopy;
  });
}
export function getQuestionCountsByCategory(questions, categories) {
  const countsByCategory = {};
  questions.forEach(question => {
    if (question.categories) {
      question.categories.forEach(category => {
        const categoryId = category.id;
        if (!countsByCategory[categoryId]) {
          countsByCategory[categoryId] = {
            name: category.name,
            id: categoryId,
            _ts: category._ts,
            count: 0
          };
        }
        countsByCategory[categoryId].count++;
      });
    }
  });
  return Object.values(countsByCategory);
}

function getQuestionsByCategory(categories, questions, categoryName) {
  // Find the category object with the specified name
  const category = categories.find(cat => cat.name === categoryName);

  // If category is not found, return an empty array
  if (!category) {
    return [];
  }

  // Find all questions that have the category ID
  const questionsInCategory = questions.filter(q => q.categories && q.categories.some(c => c.id === category.id));

  return questionsInCategory;
}

export function getQuestionsByCategories(categories, questions) {
  const categoryIds = categories.map(category => category.id);
  return questions.filter(question => {
    if (!question.categories) {
      return false;
    }
    const questionCategoryIds = question.categories.map(category => category.id);
    return questionCategoryIds.some(id => categoryIds.includes(id));
  });
}
