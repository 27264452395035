import { Typography, MenuItem, Button, TextField } from "@mui/material";
import defaultLocale from "../QuizLib/Locale";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { quizIntro } from "../shared/quizIntro";
import { QuestionsContext } from "../context/QuestionsContext";
import { UsersContext } from "../context/UsersContext";

export const QuizOW = () => {
  const [disabled, setDisabled] = useState(true)
  const [selectedUser, setSelectedUser] = useState("");

  const { users } = useContext(UsersContext)
  const { questions } = useContext(QuestionsContext);

  const navigate = useNavigate();

  const appLocale = {
    ...defaultLocale,
    ...questions.appLocale,
  };

  const handleOnChange = (user) => {
    setSelectedUser(user)
    setDisabled(!user)
  }

  const handleOnClick = () => {
    if(selectedUser.length > 0) {
      navigate("/quiz", { state: { selectedUser: selectedUser } })
    }
  }

  return (
    <div className="react-quiz-container">
      <div>
        <Typography variant='h1' className='quiz-title'>{quizIntro.quizTitle}</Typography>
        <div>{appLocale.landingHeaderText.replace('<questionLength>', questions.filter(question => question.active).length)}</div>
        {quizIntro.quizSynopsis
          && (
          <div className="quiz-synopsis">
            <Typography variant='body1'>
              {quizIntro.quizSynopsis}
            </Typography>
          </div>
          )}
        <div className="startQuizWrapper">
          <TextField
            onChange={(e) => handleOnChange(e.target.value)}
            defaultValue=""
            label="Select User" 
            className="username"
            id="txt-outlined-basic"
            variant="outlined"
            required
            select
          >
          {users.filter(user => user.active).map((user) => (
            <MenuItem key={user.name} value={user.name}>
              {user.label}
            </MenuItem>
          ))}
          </TextField>
          <Button 
            variant="contained" 
            onClick={() => handleOnClick()}
            className="startQuizBtn btn"
            disabled={disabled}
          >
            {appLocale.startQuizBtn}
          </Button>
        </div>
      </div>
    </div>
  )
}