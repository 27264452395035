import { Typography, Tooltip, IconButton } from "@mui/material"
import WindowIcon from '@mui/icons-material/Window';
import GoogleIcon from '@mui/icons-material/Google';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { BrokenGlasses } from "../Components/BrokenGlasses";


export const Login = () => {

  const { userInfo } = useContext(AuthContext)
  const isAdmin = userInfo?.userRoles?.includes("admin");

  const prov = [
    {
      name: 'aad',
      label: 'Microsoft',
      icon: <WindowIcon fontSize="large"/>
    },
    {
      name: 'google',
      label: 'Google',
      icon: <GoogleIcon fontSize="large"/>
    },
    {
      name: 'twitter',
      label: 'Twitter',
      icon: <TwitterIcon fontSize="large"/>
    }
  ]
  const redirect = "/results";

  return (
      <div className="login-container">
        <Typography variant="h1" className="login-heading">
          { userInfo && !isAdmin ? "Unauthorized User" : "Admin Login" }
        </Typography>
        {
          userInfo && !isAdmin && <BrokenGlasses/>
        }
        <Typography variant="body1" className="login-description">
        { userInfo && !isAdmin 
          ? "Please log out and log back in with an administrator account" 
          : "Please login using one of the following accounts" }
        </Typography>
        { (!userInfo || isAdmin ) && <div className="login-buttons">
        { prov.map((provider) => (
          <Tooltip title={provider.label} key={provider.name}>
            <IconButton className="loginBtn"
              href={`/.auth/login/${provider.name}?post_login_redirect_uri=${redirect}`}
            >
              {provider.icon}
            </IconButton>
          </Tooltip>
        ))}
        </div>}
      </div>
  )
}