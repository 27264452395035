import { Typography } from '@mui/material';
import React from 'react';

const Explanation = function ({ question, isResultPage }) {
  const { explanation } = question;

  if (!explanation) {
    return null;
  }

  if (isResultPage) {
    return (
      <div className="explanation">
        <Typography variant='body1'>
          {explanation}
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <br />
      {explanation}
    </div>
  );
};

export default Explanation;
