import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function QuestionsInsightsCards({questionsInsightsToDisplay }) {

  const pictureUrl = process.env.REACT_APP_IMAGE_URL;

  const styles = {
    media: {
      height: 0,
      paddingTop: '15%',
      marginRight: '65%',
      backgroundSize: '100%',
    }
  };
  return (
    <>
      {questionsInsightsToDisplay && questionsInsightsToDisplay.map(question => (
        <Card sx={{ maxWidth: "100%" }} key={question.id} className="question-card">
          <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {question.question}
          </Typography>
            {
              question.questionPic &&
              <CardMedia className='question-picture'
                image={pictureUrl + question.questionPic}
                title="Frame"
                style={styles.media} 
              />
            }
            <Typography>
              Correct: 
              <b>
                <span className={
                  question.successPercentage >= 70 ?
                  "green" :
                  question.successPercentage >= 50 ?
                  "yellow" :
                  "red" 
                  }> {question.successPercentage}%
                </span>
              </b>
            </Typography>
            <Typography>
                Users that answered correctly: <b>{question.timesCorrect}</b>
            </Typography>
            <Typography>
                Users that answered incorrectly: <b>{question.timesIncorrect}</b>
            </Typography>
            <div className='questions-insights-by-result'>
            <div className='correct-users'>
            {
              question.correctUsers.length > 0 ?
                <>
                  <Typography className='answered-heading'>
                    <b>Answered correctly</b>
                  </Typography>
                    {question.correctUsers.map((user, index) => (
                      <Typography key={index} className="green">
                        {user}
                      </Typography>
                    ))}
                </>
              : 
              <Typography className='answered-heading'>
                <b>No users answered correctly</b>
              </Typography>
              }
              </div>
            <div className='incorrect-users'>
            {
                question.incorrectUsers.length > 0 ?
                <>
                  <Typography className='answered-heading'>
                    <b>Answered incorrectly</b>
                  </Typography>
                    {question.incorrectUsers.map((user, index) => (
                      <Typography key={index} className="red">
                        {user}
                      </Typography>
                    ))}
                </>
              : 
              <Typography className='answered-heading'>
                <b>No users answered incorrectly 🎉</b>
              </Typography>
              }
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </>
  );
}