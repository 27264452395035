import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import DeleteIcon from '@mui/icons-material/Delete';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getTimeFromEpoch } from '../shared/tools';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { useContext } from 'react';
import { UsersContext } from '../context/UsersContext';
import { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import AlertDialogSlide from './AlertDialogSlide';


export default function UsersTable() {

  const { users, deleteUsersHandler } = useContext(UsersContext)
  const [userToDelete, setUserToDelete ]  = useState({});
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleClickAgree = async () => {
    await deleteUsersHandler(userToDelete)
    setUserToDelete({})
    handleClose();
  };

  const handleClose = () => {
    setOpenConfirmation(false);
  };
  return (
      <div className="users-list">
        <TableContainer component={Paper}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            All Users
          </Typography>
        </Toolbar>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Users</TableCell>
                <TableCell align="right">Admin</TableCell>
                <TableCell align="right">Active</TableCell>
                <TableCell align="right">Created</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow
                  key={user.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row"> {user.name} </TableCell>
                  <TableCell align="right">{user.admin ? "Yes" : "No"}</TableCell>
                  <TableCell align="right">{user.active ? "Yes" : "No"}</TableCell>
                  <TableCell align="right">{getTimeFromEpoch(user._ts || Date.now()/ 1000)}</TableCell>
                  <TableCell align="right">{
                    <Tooltip title="Delete">
                      <IconButton onClick={() => {
                          setUserToDelete(user)
                          setOpenConfirmation(true)
                        }}>
                        <DeleteIcon/>
                      </IconButton>
                    </Tooltip>
                  }</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {openConfirmation && (
          <AlertDialogSlide
            title="Are you sure?"
            description={`Are you sure you want to delete ${userToDelete.name}? This action cannot be undone.`}
            agreeText="Delete"
            disagreeText="Cancel"
            open={openConfirmation}
            onAgree={handleClickAgree}
            onClose={handleClose}
          />
          )}
      </div>
  );
}