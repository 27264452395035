import { Typography } from "@mui/material"
import QuestionsTable from "../Components/QuestionsTable";


export const Questions = () => {
  return (
    <div className="manage-questions-container">
        <Typography 
          variant="h1"
          className="questions-heading"
        >
          Questions
        </Typography>
        <QuestionsTable/>
    </div>
  )
}