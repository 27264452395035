import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import QuizIcon from '@mui/icons-material/Quiz';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import GradingIcon from '@mui/icons-material/Grading';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Drawer from './Drawer';
import { Leaderboard } from '@mui/icons-material';

export default function NavBar() {
  const [value, setValue] = useState('home');
  const { userInfo } = useContext(AuthContext);
  const navigate = useNavigate()
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);

  const isAdmin = userInfo?.userRoles?.includes("admin");

  const handleChange = (event, newValue) => {
    if(newValue !== "menu") setValue(newValue);

    switch(newValue) {
      case "home":
        navigate("/");
        break;

      case "login":
        isAdmin ? navigate("/results")
        : navigate("/login");
        break;

      case "results":
        isAdmin ? navigate("/results")
        : navigate("/login");
        break;

      case "users":
        isAdmin ? navigate("/users")
        : navigate("/login");
        break;

      case "questions":
        isAdmin ? navigate("/questions")
        : navigate("/login");
        break;

      case "menu":
        isAdmin ? setOpenMenu(!openMenu)
        : navigate("/login");
        break;

      case "insights":
        isAdmin ? navigate("/insights")
        : navigate("/login");
        break;

      case "leaderboard":
        navigate("/leaderboard")
        break;

      default:
        break;
    }
  };
  
  useEffect(() => {
    const path = location.pathname;
  
    switch (path) {
      case '/':
        setValue('home');
        break;

      case '/quiz':
        setValue('home');
        break;

      case '/login':
        setValue('login');
        break;

      case '/results':
        setValue('results');
        break;
  
      case '/users':
        setValue('menu');
        break;

      case '/insights':
        setValue('menu');
        break;
  
      case '/questions':
        setValue('questions');
        break;

      case '/leaderboard':
        setValue('leaderboard');
        break;
  
      default:
        setValue('/');
        break;
    }
  }, [location]);

  return (
    <BottomNavigation sx={{ width: "100%", height: "4rem" }} value={value} onChange={handleChange}>
      <BottomNavigationAction
        label="Home"
        value="home"
        icon={
          <Tooltip title="Home">
            <HomeIcon />
          </Tooltip>
        }
      />
      <BottomNavigationAction
        label="Leaderboard"
        value="leaderboard"
        icon={
          <Tooltip title="Leaderboard">
            <Leaderboard />
          </Tooltip>
        }
      />
      {
        userInfo?.userRoles.includes("admin") ?
        <BottomNavigationAction
        label="Results"
        value="results"
        icon={
          <Tooltip title="Quiz Results">
            <GradingIcon />
          </Tooltip>
        }
        />
        :
        <BottomNavigationAction
        label="Login"
        value="login"
        icon={
          <Tooltip title="Admin Login">
            <LockPersonIcon />
          </Tooltip>
        }
        />
      }
      {userInfo?.userRoles?.includes("admin") &&
        <BottomNavigationAction
        label="Questions"
        value="questions"
        icon={
          <Tooltip title="Questions">
            <QuizIcon />
          </Tooltip>
        }
        />
      }
      {userInfo && !isAdmin &&
        <BottomNavigationAction
        label="Logout"
        value="logout"
        icon={
          <Tooltip title="Logout">
            <LogoutIcon />
          </Tooltip>
        }
        href={`/.auth/logout?post_logout_redirect_uri=/`}
      />
      }
      {isAdmin &&
        <BottomNavigationAction
        label="Menu"
        value="menu"
        icon={
          <Tooltip title="Menu">
            <MenuIcon />
          </Tooltip>
        }
        />
      }
      {
        isAdmin &&
        <Drawer 
          open={openMenu} 
          setOpen={setOpenMenu} 
          handleChange={handleChange}
        />
      }
    </BottomNavigation>
  );
}