import { Card, CardContent, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { getTimeFromEpoch } from "../shared/tools"

export const ResultsCards = ({ selectedUsers }) => {

  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers(selectedUsers)
  }, [selectedUsers])

  return (
    <>
    {users.map((item, i) => (
        <Card 
          sx={{ maxWidth: "100%" }} 
          key={item._ts} className='user-responses-card'
          >
          <CardContent key={item.id}>
            <Typography variant="h4" className="user-name">
              {item.userName}
            </Typography>
            <Typography variant="body1" className="questions-summary">
              <b>Total questions: </b>{item.numberOfQuestions}
              <br/>
              <b>Correct answers: </b>{item.numberOfCorrectAnswers}
              <br/>
              <b>Incorrect answers: </b>{item.numberOfIncorrectAnswers}
              <br/>
              <b>Date: </b>{getTimeFromEpoch(item._ts)}
            </Typography>
          </CardContent>
          <CardContent key={i}>
            {item.questions.map((q, j) => {
              return(
                <div className="user-qa" key={j}>
                  <Typography variant="body1" className="question" key={q.id}>
                    {q.question}
                  </Typography>
                    {!Array.isArray(item.userInput[j]) ?
                      <Typography variant="body1" className={q.answers[item.userInput[j]-1] === 
                        q.answers[q.correctAnswer-1] ? 'single-answer correct' : 'single-answer incorrect'}>
                        {q.answers[item.userInput[j]-1]}
                      </Typography>
                      : 
                      item.userInput[j].map((answ, y) => {
                        return <Typography 
                        variant="body1" 
                        key={y}
                        className={q.correctAnswer.includes(answ) ? 'multiple-answer correct' : 'multiple-answer incorrect'}
                        >
                          {q.answers[answ - 1]}
                        </Typography>
                      })
                    }
                </div>
              )
            })}
          </CardContent>
      </Card>
    ))}
    </>
  )
}