import React, { useState, useEffect, useCallback, useContext } from 'react';
import Core from './Core';
import defaultLocale from './Locale';
import './styles.css';
import { useLocation } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { QuestionsContext } from '../context/QuestionsContext';
import { quizIntro } from '../shared/quizIntro';
import { ResultsContext } from '../context/ResultsContext';

const Quiz = function ({
  customResultPage, showInstantFeedback, continueTillCorrect, revealAnswerOnSubmit, allowNavigation, onQuestionSubmit, disableSynopsis,
}) {
  const { questions: contextQuestions } = useContext(QuestionsContext);
  const { createResultHandler } = useContext(ResultsContext);

  const quiz = {
    quizTitle: quizIntro.quizTitle,
    quizSynopsis: quizIntro.quizSynopsis,
    nrOfQuestions: contextQuestions.length,
    questions: contextQuestions.filter(question => question.active)
  }
  const showDefaultResult = true;
  const shuffle = true;

  const onComplete = (result) => {
    if(result.userInput.length > 0) {
      createResultHandler(result)
    }
  }

  const [questions, setQuestions] = useState(quiz.questions);
  const [selectedUser, setSelectedUser] = useState("")

  const navigate = useNavigate();
  const location = useLocation();

  const nrOfQuestions = (quiz.nrOfQuestions && quiz.nrOfQuestions < quiz.questions.length) ? quiz.nrOfQuestions : quiz.questions.length;

  const shuffleQuestions = useCallback((q) => {
    for (let i = q.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      [q[i], q[j]] = [q[j], q[i]];
    }
    q.length = nrOfQuestions;
    return q;
  },[]);

  useEffect(() => {
    setSelectedUser(location.state?.selectedUser);
    if (shuffle) {
      setQuestions(shuffleQuestions(quiz.questions));
    } else {
      quiz.questions.length = nrOfQuestions;
      setQuestions(quiz.questions);
    }

    setQuestions(questions.map((question, index) => ({
      ...question,
      questionIndex: index + 1,
    })));
  }, [location.state?.selectedUser]);

  const validateQuiz = (q) => {
    if (!q) {
      console.error('Quiz object is required.');
      return false;
    }

    for (let i = 0; i < questions.length; i += 1) {
      const {
        question, questionType, answerSelectionType, answers, correctAnswer,
      } = questions[i];
      if (!question) {
        console.error("Field 'question' is required.");
        return false;
      }

      if (!questionType) {
        console.error("Field 'questionType' is required.");
        return false;
      }
      if (questionType !== 'text' && questionType !== 'photo') {
        console.error("The value of 'questionType' is either 'text' or 'photo'.");
        return false;
      }

      if (!answers) {
        console.error("Field 'answers' is required.");
        return false;
      }
      if (!Array.isArray(answers)) {
        console.error("Field 'answers' has to be an Array");
        return false;
      }

      if (!correctAnswer) {
        console.error("Field 'correctAnswer' is required.");
        return false;
      }

      let selectType = answerSelectionType;

      if (!answerSelectionType) {
        // Default single to avoid code breaking due to automatic version upgrade
        console.warn('Field answerSelectionType should be defined since v0.3.0. Use single by default.');
        selectType = answerSelectionType || 'single';
      }

      if (selectType === 'single' && !(typeof selectType === 'string' || selectType instanceof String)) {
        console.error('answerSelectionType is single but expecting String in the field correctAnswer');
        return false;
      }

      if (selectType === 'multiple' && !Array.isArray(correctAnswer)) {
        console.error('answerSelectionType is multiple but expecting Array in the field correctAnswer');
        return false;
      }
    }

    return true;
  };

  if (!validateQuiz(quiz)) {
    return (null);
  }

  const appLocale = {
    ...defaultLocale,
    ...quiz.appLocale,
  };

  return (
    <>
      {contextQuestions.filter(question => question.active).length < 1 ?
        <Typography>
          No questions in the quiz yet.
        </Typography>
      :
      selectedUser ?
      <div className="react-quiz-container">
        <Core
          questions={questions}
          showDefaultResult={showDefaultResult}
          onComplete={onComplete}
          customResultPage={customResultPage}
          showInstantFeedback={showInstantFeedback}
          continueTillCorrect={continueTillCorrect}
          revealAnswerOnSubmit={revealAnswerOnSubmit}
          allowNavigation={allowNavigation}
          appLocale={appLocale}
          onQuestionSubmit={onQuestionSubmit}
          userName={selectedUser}
        />
      </div>  
      : <div className='no-user-selected'>
        <Typography>
          You must select a user before starting the quiz.
        </Typography>
        <div className='homeBtn'>
          <Button variant='contained' onClick={() => navigate("/")}>
            Home
          </Button>
        </div>
      </div>
    }
    </>
  );
};

export default Quiz;
