import { Typography } from "@mui/material"
import QuestionsInsightsTable from "../Components/QuestionsInsightsTable";
import UsersInsightsTable from "../Components/UsersInsightsTable";


export const Insights = () => {
  return (
    <div className="insights-container">
        <Typography 
          variant="h1"
          className="insights-heading"
        >
          Insights
        </Typography>
        <QuestionsInsightsTable/>
        <UsersInsightsTable/>
    </div>
  )
}