import { Typography } from "@mui/material"
import LeaderboardTable from "../Components/LeaderboardTable";

export const Leaderboard = () => {

  return (
    <div className="leaderboard-container">
        <Typography variant="h1">Leaderboard</Typography>
        <LeaderboardTable/>
    </div>
  )
}