import { Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { BrokenGlasses } from "../Components/BrokenGlasses";

export const NotFound = () => {
  const navigate = useNavigate();

  return(
    <div className="not-found-container">
      <Typography variant="h1" className="not-found-heading">
        404 Not Found
      </Typography>
      <BrokenGlasses/>
      <div className='homeBtn'>
          <Button variant='outlined' onClick={() => navigate("/")}>
            Home
          </Button>
        </div>
    </div>
  )
}