import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeselectIcon from '@mui/icons-material/Deselect';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { animateScroll } from 'react-scroll';
import QuestionsCards from './QuestionsCards';
import { QuestionsContext } from '../context/QuestionsContext';
import AlertDialogSlide from './AlertDialogSlide';
import { getQuestionsByCategories, getTimeFromEpoch } from '../shared/tools';
import QuestionModal from './QuestionModal';
import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import CategoryModal from './CategoryModal';
import { QuestionsFilterDialog } from './QuestionsFilterDialog';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'categories') {
    // Compare categories array lengths
    if (!a[orderBy] && !b[orderBy]) {
      return 0;
    } else if (!a[orderBy]) {
      return 1;
    } else if (!b[orderBy]) {
      return -1;
    } else if (a[orderBy].length < b[orderBy].length) {
      return -1;
    } else if (a[orderBy].length > b[orderBy].length) {
      return 1;
    }
    // Compare category names if lengths are equal
    for (let i = 0; i < a[orderBy].length; i++) {
      if (a[orderBy][i].name < b[orderBy][i].name) {
        return -1;
      } else if (a[orderBy][i].name > b[orderBy][i].name) {
        return 1;
      }
    }
    return 0;
  } else {
    // Compare other columns numerically
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'question',
    numeric: false,
    disablePadding: true,
    label: 'Question',
  },
  {
    id: 'active',
    numeric: true,
    disablePadding: false,
    label: 'Active',
  },
  {
    id: 'categories',
    numeric: false,
    disablePadding: false,
    label: 'Categories',
  },
  {
    id: '_ts',
    numeric: true,
    disablePadding: false,
    label: 'Date',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all results',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({selected, setSelected, setQuestionsToDisplay, questions, handleDelete, refetchQuestionsHandler, setRows}) {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const { activateDeactivateAllQuestions } = useContext(QuestionsContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [openQuestionFilter, setOpenQuestionFilter] = useState(false);
  const [checkedCategories, setCheckedCategories] = useState([]);

  const handleOpenQuestionFilter = () => {
    setOpenQuestionFilter(true)
  }

  const handleCloseQuestionFilter = () => {
    setOpenQuestionFilter(false)
  }

  const handleApplyFilters = () => {
      setRows(getQuestionsByCategories(checkedCategories, questions))
    setOpenQuestionFilter(false)
  }

  const handleRemoveFilters = () => {
    setCheckedCategories([])
    setRows(questions)
    setOpenQuestionFilter(false)
  }
  const handleOpenCategoryModal = () => {
    setOpenCategoryModal(true)
    setAnchorEl(null);
  }

  const handleCloseCategoryModal = () => {
    setOpenCategoryModal(false)
  }

  const handleOpenQuestionModal = () => {
    setOpenQuestionModal(true);
    setAnchorEl(null);
  }

  const handleCloseQuestionModal = () => {
    setOpenQuestionModal(false)
    setSelected([])
  }

  const handleClickAgree = () => {
    handleDelete(selected);
    setSelected([])
    setQuestionsToDisplay([])
    handleClose();
  };

  const handleClose = () => {
    setOpenConfirmation(false);
  };

  function scrollToTableEnd() {
    const heights = Array.from(document.querySelectorAll('.questions-heading, questions-list-heading, #table-box')).map(e => e.offsetHeight);
    const totalHeight = heights.reduce((acc, h) => acc + h, 0);
  
    animateScroll.scrollTo(totalHeight, {
      duration: 1000,
      delay: 0,
    });
  }
  
  const handleOpen = (questionsSelected) => {
    let selectedQuestions = []
    for(let question of questions) {
      for(let questionSelected of questionsSelected) {
        if(question.id === questionSelected.id) selectedQuestions.push(question)
      }
    }
    setQuestionsToDisplay(selectedQuestions)
    scrollToTableEnd()
  }
  
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selected.length > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {selected.length > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selected.length} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          All Questions
        </Typography>
      )}
      {selected.length > 0 ? (
        <>
          <Tooltip title='Clear'>
            <IconButton onClick={() => {
              setSelected([])
              setQuestionsToDisplay([])
            }}>
              <HighlightOffIcon/>
            </IconButton>
          </Tooltip>
          {
            selected.every(q => q.active) && (
              <Tooltip title="Deactivate">
                <IconButton
                  onClick={async () => {
                    await activateDeactivateAllQuestions(selected, false);
                  }}
                >
                  <DeselectIcon />
                </IconButton>
              </Tooltip>
            )
          }
          {
            selected.every(q => !q.active) && (
              <Tooltip title="Activate">
                <IconButton
                  onClick={async () => {
                    await activateDeactivateAllQuestions(selected, true);
                  }}
                >
                  <SelectAllIcon />
                </IconButton>
              </Tooltip>
            )
          }
          {
            !selected.every(q => q.active) && !selected.every(q => !q.active) && (
              <>
                <Tooltip title="Activate">
                  <IconButton
                    onClick={async () => {
                      await activateDeactivateAllQuestions(selected, true);
                    }}
                  >
                    <SelectAllIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deactivate">
                  <IconButton
                    onClick={async () => {
                      await activateDeactivateAllQuestions(selected, false);
                    }}
                  >
                    <DeselectIcon />
                  </IconButton>
                </Tooltip>
              </>
            )
          }
          {
          selected.length === 1 &&
            <Tooltip title='Edit'>
              <IconButton onClick={() => {
                handleOpenQuestionModal()
              }}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
          }
          <Tooltip title='Open'>
            <IconButton onClick={() => {
              handleOpen(selected)
            }}>
              <OpenInFullIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => {
                  setOpenConfirmation(true)
                }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          {openConfirmation && (
          <AlertDialogSlide
            title="Are you sure?"
            description={selected.length > 1 
              ? `Are you sure you want to delete these ${selected.length} questions? This action cannot be undone.`
              : `Are you sure you want to delete this question?
                This action cannot be undone.`
            }
            agreeText="Delete"
            disagreeText="Cancel"
            open={openConfirmation}
            onAgree={handleClickAgree}
            onClose={handleClose}
          />
          )}
        </>
      ) : (
        <>
        <Tooltip title="Add">
          <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem 
            onClick={() => handleOpenQuestionModal()}>
              Add question
          </MenuItem>
          <MenuItem 
            onClick={() => handleOpenCategoryModal()}>
              Add category
          </MenuItem>
        </Menu>
        {
          checkedCategories.length === 0 ?
          <Tooltip title="Filter">
            <IconButton onClick={() => handleOpenQuestionFilter()}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          :
          <Tooltip title="Remove filters">
            <IconButton onClick={() => handleRemoveFilters()}>
              <FilterListOffIcon />
            </IconButton>
          </Tooltip>
        }

        <Tooltip title="Reload">
          <IconButton onClick={() => {
              refetchQuestionsHandler()
            }}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
        </>

      )}
      {openQuestionModal && (
        <QuestionModal
          handleCloseQuestionModal={handleCloseQuestionModal}
          openQuestionModal={openQuestionModal}
          questionToBeEdited={selected[0] || null}
        />
      )}
      {
        openCategoryModal && (
        <CategoryModal
          handleCloseCategoryModal={handleCloseCategoryModal}
          openCategoryModal={openCategoryModal}
          categoryToBeEdited={selected[0] || null}
        />
        )
      }
      {
        openQuestionFilter && (
          <QuestionsFilterDialog
            onClose={handleCloseQuestionFilter}
            open={openQuestionFilter}
            onApplyFilters={handleApplyFilters}
            onRemoveFilters={handleRemoveFilters}
            checkedCategories={checkedCategories}
            setCheckedCategories={setCheckedCategories}
          />
        )
      }
    </Toolbar>
  );
}

export default function QuestionsTable() {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('_ts');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [questionsToDisplay, setQuestionsToDisplay] = useState([])


  const { questions, refetchQuestionsHandler, deleteQuestionHandler } = useContext(QuestionsContext);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [rows, setRows] = useState(questions)

  useEffect(() => {
    setRows(questions)
  }, [questions])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows;
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.findIndex(selectedRow => {
      return selectedRow.id === row.id;
    });
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => {
    for(let itemSelected of selected) {
      if(itemSelected.id === row.id) return true
    }
    return false
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
      <>
        <Box sx={{ width: '100%' }} id="table-box">
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar 
              selected={selected}
              setSelected={setSelected}
              questions={questions}
              setQuestionsToDisplay={setQuestionsToDisplay}
              handleDelete={deleteQuestionHandler}
              refetchQuestionsHandler={refetchQuestionsHandler}
              setRows={setRows}
            />
            <TableContainer>
              <Table
                sx={{ minWidth: 350 }}
                aria-labelledby="tableTitle"
                size={'small'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
  
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.question}
                          </TableCell>
                          <TableCell align="right">
                            {row.active ? "Yes" : "No"}
                          </TableCell>
                          <TableCell align='center'>
                          {row.categories?.length > 0 ? row.categories.map((category, index) => (
                            <React.Fragment key={category.id}>
                              {category.name}
                              {index === row.categories.length - 1 ? "" : ", "}
                            </React.Fragment>
                          )) : "None"}
                          </TableCell>
                          <TableCell 
                            align="right"
                          >
                            {getTimeFromEpoch(row._ts || Date.now()/ 1000)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (33) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      <QuestionsCards 
        questionsToDisplay={questionsToDisplay}
        setQuestionsToDisplay={setQuestionsToDisplay}
      />
    </>
  );
}