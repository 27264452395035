import { createContext, useState, useEffect } from 'react';
import { deleteCategory, fetchCategories, insertCategory, updateCategory } from '../shared/tools';

export const CategoriesContext = createContext();

export const CategoriesProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  

  useEffect(() => {
    const loadCategories = async () => {
      const response = await fetchCategories();
      setCategories(response.resources);
      setIsLoading(false);
    };
    loadCategories();
  }, []);

  
  const createCategoryHandler = async (newCategory) => {
    insertCategory(newCategory).then(() => {
      setCategories([...categories, newCategory])
    }).catch((e) => console.log(e))
  };

  const updateCategoryHandler = async (categoryId, updates) => {
    updateCategory(categoryId, updates).then((updatedCategory) => {
        const updatedCategories = categories.map((category) =>
        category.id === categoryId ? updatedCategory : category
      );
      setCategories(updatedCategories);
    })
  };

  const deleteCategoryHandler = async (categoriesToBeDeleted) => {
    let newCategories = [...categories];
    for(let categoryToBeDeleted of categoriesToBeDeleted) {
      const code = await deleteCategory(categoryToBeDeleted);
      if(code === 204) {
        newCategories = newCategories.filter(cat => cat.id !== categoryToBeDeleted.id);
        setCategories(newCategories)
      }
    }
  };

  const refetchCategoriesHandler = async () => {
    setIsLoading(true);
    const response = await fetchCategories();
    setCategories(response.resources);
    setIsLoading(false);
  };

  return (
    <CategoriesContext.Provider
      value={{ 
        categories,
        isLoading,
        createCategoryHandler,
        deleteCategoryHandler,
        refetchCategoriesHandler,
        updateCategoryHandler
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};