import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import styled from '@emotion/styled';
import CategoryForm from './CategoryForm';

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 300px;
  background-color: white;
  max-height: 85vh;
  padding: 1rem;
  overflow: auto;
  border-radius: 1rem;
`

export default function CategoryModal({handleCloseCategoryModal, openCategoryModal, categoryToBeEdited}) {

  const handleClickCancel = () => {
    handleCloseCategoryModal()
  }
  return (
    <div className='category-modal'>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCategoryModal}
        onClose={handleCloseCategoryModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openCategoryModal}>

          <BoxStyled>
          <CategoryForm 
            categoryToBeEdited={categoryToBeEdited}
            handleClickCancel={handleClickCancel}
          />
          </BoxStyled>
        </Fade>
      </Modal>
    </div>
  );
}