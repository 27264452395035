import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function UsersInsightsCard({userInsightsToDisplay }) {

  const pictureUrl = process.env.REACT_APP_IMAGE_URL;
  
  const styles = {
    media: {
      height: 0,
      paddingTop: '15%',
      marginRight: '65%',
      backgroundSize: '100%',
    }
  };

  return (
    <>
      {userInsightsToDisplay && userInsightsToDisplay.map(userInsightToDisplay => (
        <Card sx={{ maxWidth: "100%" }} key={userInsightToDisplay.id} className="user-card">

          <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {userInsightToDisplay.userName}
          </Typography>
            <Typography>
              Correct: 
              <b>
                <span className={
                  userInsightToDisplay.successPercentage >= 70 ?
                  "green" :
                  userInsightToDisplay.successPercentage >= 50 ?
                  "yellow" :
                  "red" 
                  }> {userInsightToDisplay.successPercentage}%
                </span>
              </b>
            </Typography>
            <Typography>
                Questions answered correctly: <b>{userInsightToDisplay.timesCorrect}</b>
            </Typography>
            <Typography>
              Questions answered incorrectly: <b>{userInsightToDisplay.timesIncorrect}</b>
            </Typography>
            <div className='questions-insights-correct'>
              {
                userInsightToDisplay.questionsCorrect.map((questionCorrect, i) => (
                  <div key={i}>
                    <Typography>
                      {questionCorrect.question}
                    </Typography>
                    {questionCorrect.questionPic &&
                    <CardMedia className='question-picture'
                      image={pictureUrl + questionCorrect.questionPic}
                      title="Frame"
                      style={styles.media} 
                    />}               
                  </div>
                ))
              }
            </div>
            <div className='questions-insights-incorrect'>
              {
                userInsightToDisplay.questionsIncorrect.map((questionIncorrect, i) => (
                  <div key={i}>
                    <Typography>
                      {questionIncorrect.question}
                    </Typography>
                    {questionIncorrect.questionPic &&
                    <CardMedia className='question-picture'
                      image={questionIncorrect.questionPic}
                      title="Frame"
                      style={styles.media} 
                    />}               
                  </div>
                ))
              }
            </div>
          </CardContent>
        </Card>
      ))}
    </>
  );
}