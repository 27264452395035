import { Typography } from "@mui/material"
import UsersTable from "../Components/UsersTable";
import { AddUserForm } from "../Components/AddUserForm";

export const ManageUsers = () => {

  return (
    <div className="manage-users-container">
        <Typography variant="h1">Users</Typography>
        <UsersTable />
        <AddUserForm/>
    </div>
  )
}