import React from 'react';
import { TextField } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';

const QuizResultFilter = function ({ filteredValue, handleChange, appLocale }) {

  const options = [
    {
      value: "all",
      label: appLocale.resultFilterAll,
    },
    {
      value: "correct",
      label: appLocale.resultFilterCorrect,
    },
    {
      value: "incorrect",
      label: appLocale.resultFilterIncorrect,
    },
  ]
  return (
    <div className='quiz-result-buttons'>
      <TextField
        onChange={handleChange}
        defaultValue="all"
        className="quiz-result-filter"
        select
      >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
      </TextField>
    </div>
  );
};

export default QuizResultFilter;
