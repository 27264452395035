import { createContext, useState, useEffect } from 'react';
import { getUserInfo } from "../shared/tools";


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    const loadUser = async () => {
      setUserInfo(await getUserInfo())
    };
    loadUser();
  }, []);


  return (
    <AuthContext.Provider value={{ userInfo }}>
      {children}
    </AuthContext.Provider>
  );
};