import { Typography, Tooltip, TextField, Switch, FormGroup, FormControlLabel, Button } from "@mui/material"
import { useState } from "react";
import { useContext } from "react";
import { UsersContext } from "../context/UsersContext";

export const AddUserForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  const { createUserHandler } = useContext(UsersContext)

  const onClickSave = () => {
    const newUser = {
      name: `${firstName} ${lastName}`,
      label: firstName,
      admin: isAdmin,
      active: isActive,
    }
    createUserHandler(newUser)
    setFirstName("");
    setLastName("");
    setIsActive(true);
    setIsAdmin(false);
  }

  const isSaveDisabled = firstName.trim().length < 2 || lastName.trim().length < 2;
  
  return (
    <div className="add-user-container">
      <Typography variant="h4">Add User</Typography>
      <div className="add-user-form">
        <FormGroup>
          <TextField label="First Name" variant="standard" required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          <TextField label="Last Name" variant="standard" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
          <div className="add-user-switches">
            <FormControlLabel 
              control={
                <Tooltip title={"User profile to be active"}>
                  <Switch checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
                </Tooltip>
              }
              label="Active"
            />
            <FormControlLabel 
              control={
                <Tooltip title={"User profile to be admin"}>
                  <Switch checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} />
                </Tooltip>
              }
              label="Admin"
            />
          </div>
          <div className="add-user-btn">
            <Button variant="outlined" onClick={onClickSave} 
              disabled={isSaveDisabled} style={{width: "50%"}}>
              Save
            </Button>
          </div>
        </FormGroup>
      </div>
    </div>
  )
}