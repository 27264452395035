import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getTimeFromEpoch } from '../shared/tools';
import { Typography } from '@mui/material';
import { useContext } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { ResultsContext } from '../context/ResultsContext';
import { UsersContext } from '../context/UsersContext';
import { getUserInsights, getTopFiveUsers, getTopFiveUsersThisWeek } from '../shared/tools';


export default function LeaderboardTable() {

  const { results } = useContext(ResultsContext);
  const { users } = useContext(UsersContext)
  
  const adminUsers = users.filter(user => user.admin)

  const topFiveUsers = getTopFiveUsers(getUserInsights(results, adminUsers));

  return (
      <div className="top-five-list">
        <TableContainer component={Paper}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Top Five
          </Typography>
        </Toolbar>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Ranking</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="center">Score</TableCell>
                <TableCell align="right">Last Submission</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topFiveUsers.map((user, i) => (
                <TableRow
                  key={user.userName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell 
                    align="left" 
                    className={`ranking ${i + 1 > 3 ? 'no-medal' : 'medal'}`}
                  >
                    {i + 1 <= 3 ? ['🥇', '🥈', '🥉'][i] : i + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {user.userName}
                  </TableCell>
                  <TableCell align="center">{user.questionsCorrect.length}</TableCell>
                  <TableCell align="right">{getTimeFromEpoch(user.submissionTime || Date.now()/ 1000)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
  );
}