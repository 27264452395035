import { createContext, useState, useEffect } from 'react';
import { deleteQuestion, fetchQuestions, insertQuestion, updateQuestion } from '../shared/tools';

export const QuestionsContext = createContext();

export const QuestionsProvider = ({ children }) => {
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  

  useEffect(() => {
    const loadQuestions = async () => {
      const response = await fetchQuestions();
      setQuestions(response.resources);
      setIsLoading(false);
    };
    loadQuestions();
  }, []);

  
  const createQuestionHandler = async (newQuestion) => {
    insertQuestion(newQuestion).then(() => {
      setQuestions([...questions, newQuestion])
    }).catch((e) => console.log(e))
  };

  const updateQuestionHandler = async (questionId, updates) => {
    updateQuestion(questionId, updates).then((updatedQuestion) => {
        const updatedQuestions = questions.map((question) =>
        question.id === questionId ? updatedQuestion : question
      );
      setQuestions(updatedQuestions);
    })
  };

  const deleteQuestionHandler = async (questionsToBeDeleted) => {
    let newQuestions = [...questions];
    for(let questionToBeDeleted of questionsToBeDeleted) {
      const code = await deleteQuestion(questionToBeDeleted);
      if(code === 204) {
        newQuestions = newQuestions.filter(q => q.id !== questionToBeDeleted.id);
        setQuestions(newQuestions)
      }
    }
  };

  const activateDeactivateAllQuestions = async (questionsToBeActivated, activate) => {
    const updatedQuestions = await Promise.all(
      questionsToBeActivated.map(async (questionToBeActivated) => {
        return await updateQuestion(questionToBeActivated.id, { active: activate });
      })
    );
    const oldQuestions = questions.filter((question) => {
      return !updatedQuestions.some((updatedQuestion) => updatedQuestion.id === question.id);
    });
    setQuestions([...updatedQuestions, ...oldQuestions]);
  };

  const refetchQuestionsHandler = async () => {
    setIsLoading(true);
    const response = await fetchQuestions();
    setQuestions(response.resources);
    setIsLoading(false);
  };

  return (
    <QuestionsContext.Provider
      value={{ 
        questions,
        isLoading,
        createQuestionHandler,
        updateQuestionHandler,
        deleteQuestionHandler,
        refetchQuestionsHandler,
        activateDeactivateAllQuestions
      }}
    >
      {children}
    </QuestionsContext.Provider>
  );
};