import NotFoundImage from "../images/404.png"

export const BrokenGlasses = () => {
  return(
      <img 
        src={NotFoundImage}
        alt="Broken glasses"
        className="not-found-image"
      />
  )
}