import { Typography } from "@mui/material"
import { useContext } from "react";
import ResultsTable from "../Components/ResultsTable";
import { AuthContext } from "../context/AuthContext";

export const Results = () => {

  const { userInfo } = useContext(AuthContext);

  return (
      <div className="results">
        <Typography variant="h1" className="results-heading">
          Results
        </Typography>
        <div className="admin-user">
            <Typography variant="body1">
              Logged in as: <b>{userInfo && userInfo.userDetails}</b>
            </Typography>
        </div>
        <div className="results-table">
          <ResultsTable/>
        </div>
    </div>
  )
}