import { Typography, Tooltip, TextField, Switch, FormGroup, FormControlLabel, Button, CardMedia, MenuItem } from "@mui/material"
import { useState, useContext } from "react";
import { QuestionsContext } from "../context/QuestionsContext";
import ListBox from "./ListBox";
import { Pictures } from "../shared/pictures";

export default function QuestionForm ({questionToBeEdited, handleClickCancel}) {

  function getCorrectAnswers(question, answer) {
    let correctAnswerIndexes = question.correctAnswer;
    if (!Array.isArray(correctAnswerIndexes)) {
      correctAnswerIndexes = [correctAnswerIndexes];
    }
    return correctAnswerIndexes.map(index => question.answers[index - 1]).includes(answer);
  }
  
  const [question, setQuestion] = useState(questionToBeEdited?.question || "");
  const [answer1, setAnswer1] = useState(questionToBeEdited?.answers[0] || "");
  const [answer2, setAnswer2] = useState(questionToBeEdited?.answers[1] || "");
  const [answer3, setAnswer3] = useState(questionToBeEdited?.answers[2] || "");
  const [answer4, setAnswer4] = useState(questionToBeEdited?.answers[3] || "");
  const [answer5, setAnswer5] = useState(questionToBeEdited?.answers[4] || "");
  const [isAnswer1Correct, setIsAnswer1Correct] = useState(questionToBeEdited ?getCorrectAnswers(questionToBeEdited, answer1) : false);
  const [isAnswer2Correct, setIsAnswer2Correct] = useState(questionToBeEdited ? getCorrectAnswers(questionToBeEdited, answer2) : false);
  const [isAnswer3Correct, setIsAnswer3Correct] = useState(questionToBeEdited ? getCorrectAnswers(questionToBeEdited, answer3) : false);
  const [isAnswer4Correct, setIsAnswer4Correct] = useState(questionToBeEdited ? getCorrectAnswers(questionToBeEdited, answer4) : false);
  const [isAnswer5Correct, setIsAnswer5Correct] = useState(questionToBeEdited ? getCorrectAnswers(questionToBeEdited, answer5) : false);
  const [explanation, setExplanation] = useState(questionToBeEdited?.explanation || "");
  const [isQuestionActive, setIsQuestionActive] = useState(questionToBeEdited?.active || false);
  const [chosenCategories, setChosenCategories] = useState(questionToBeEdited?.categories || [])
  const [picture, setPicture] = useState(questionToBeEdited?.questionPic || "")

  const { questions, createQuestionHandler } = useContext(QuestionsContext);
  const {updateQuestionHandler} = useContext(QuestionsContext);

  const isSaveDisabled = 
  question.trim().length < 5 || answer1.trim().length < 1 ||
  answer2.trim().length < 1 || explanation.trim().length < 5;

  const answersFilled = [answer1, answer2, answer3, answer4, answer5]
  .filter((answ) => answ.trim().length > 0)

  const allAnswerSwitches = [isAnswer1Correct, isAnswer2Correct, isAnswer3Correct, isAnswer4Correct, isAnswer5Correct];

  const hasMoreThanOneCorrectAnswer =
  allAnswerSwitches
  .filter(checkedAnswer => checkedAnswer).length > 1;

  const getCorrectAnswersIndexes = (answers, isAnswerCorrect) => {
    const correctIndexes = [];

  for (let i = 0; i < answers.length; i++) {
    if (answers[i] && isAnswerCorrect[i]) {
      correctIndexes.push(i + 1);
    }
  }

  // The quiz accepts a string for single answer and an array for multiple
  return correctIndexes.length === 1 
  ? correctIndexes[0].toString()
  : correctIndexes;
}

function getQuestionDifferences(originalQuestion, editedQuestion) {
    const diff = {};
    const allKeys = new Set([...Object.keys(originalQuestion), ...Object.keys(editedQuestion)]);
    for (const key of allKeys) {
      if (key === "id" || key.startsWith("_")) {
        continue; // skip keys that start with _ or are "id"
      }
      if (Array.isArray(originalQuestion[key]) && Array.isArray(editedQuestion[key])) {
        if (JSON.stringify(originalQuestion[key]) !== JSON.stringify(editedQuestion[key])) {
          diff[key] = editedQuestion[key];
        }
      } else {
        if (originalQuestion[key] !== editedQuestion[key]) {
          diff[key] = editedQuestion[key];
        }
      }
    }
    return diff;
  }

  function getQuestionPics(questions) {
    const questionPics = [];
    questions.forEach(question => {
      if(question.questionPic) questionPics.push(question.questionPic);
    });
    return questionPics;
  }

  const picturesInUse = getQuestionPics(questions);
  const pictureUrl = process.env.REACT_APP_IMAGE_URL;

  const onClickSave = (closeModal = true) => {
    const result = {
      question: question.trim(),
      questionType: "text",
      answerSelectionType: hasMoreThanOneCorrectAnswer ? "multiple" : "single",
      answers: answersFilled,
      correctAnswer: getCorrectAnswersIndexes(answersFilled, allAnswerSwitches),
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: explanation.trim(),
      active: isQuestionActive,
      categories: chosenCategories,
      questionPic: picture || "",
      point: "20"
    }

    if(questionToBeEdited) {
      const updates = getQuestionDifferences(questionToBeEdited, result);
      if(Object.keys(updates).length > 0) {
        updateQuestionHandler(questionToBeEdited.id, updates)
      }
    } else {
      createQuestionHandler(result);
    }

    if(closeModal){
      handleClickCancel()
    } else {
      setQuestion("");
      setAnswer1("");
      setAnswer2("");
      setAnswer3("");
      setAnswer4("");
      setAnswer5("");
      setQuestion("");
      setQuestion("");
      setIsAnswer1Correct(false);
      setIsAnswer2Correct(false);
      setIsAnswer3Correct(false);
      setIsAnswer4Correct(false);
      setIsAnswer5Correct(false);
      setExplanation("")
      setIsQuestionActive(true);
    }
  }

  const onClickCancel = () => {
    handleClickCancel()
  }

  const styles = {
    media: {
      height: 0,
      paddingTop: '25%',
      marginTop:'1.5rem',
      backgroundSize: '60%',
    }
  };

  return(
  <div className="question-container">
      <Typography variant="h4">
        {questionToBeEdited ? "Edit" : "Add"} Question
      </Typography>
        {
          picture &&
            <CardMedia className='question-picture'
              image={pictureUrl + picture}
              title="Frame"
              style={styles.media} 
            />
        }
        <FormGroup className="questions-form">
            <TextField 
              className="question-input"
              label="Question"
              variant="standard"
              multiline
              required
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          <div className="possible-answer">
            <TextField
              className="answer-label" 
              label="Possible Answer 1"
              variant="standard"
              multiline
              required
              value={answer1}
              onChange={(e) =>  { 
                setAnswer1(e.target.value)
                if(e.target.value.trim().length < 1) setIsAnswer1Correct(false)
              }
            }
            />
            <div className="correct-answer-switch">
              <FormControlLabel
                label="Correct"
                control={
                  <Tooltip title={"Correct Answer"}>
                    <Switch 
                      checked={isAnswer1Correct}
                      disabled={answer1.trim().length < 1}
                      onChange={(e) => setIsAnswer1Correct(e.target.checked)}
                    />
                  </Tooltip>
                }
              />
            </div>
          </div>
          <div className="possible-answer">
            <TextField
              className="answer-label" 
              label="Possible Answer 2"
              variant="standard"
              multiline
              required
              value={answer2}
              onChange={(e) => {
                setAnswer2(e.target.value)
                if(e.target.value.trim().length < 1) setIsAnswer2Correct(false)
              }
            }
            />
            <div className="correct-answer-switch">
              <FormControlLabel
                label="Correct"
                control={
                  <Tooltip title={"Correct Answer"}>
                    <Switch 
                      checked={isAnswer2Correct}
                      disabled={answer2.trim().length < 1}
                      onChange={(e) => setIsAnswer2Correct(e.target.checked)}
                    />
                  </Tooltip>
                }
              />
            </div>
          </div>
          <div className="possible-answer">
            <TextField
              className="answer-label" 
              label="Possible Answer 3"
              variant="standard"
              multiline
              value={answer3}
              onChange={(e) => {
                setAnswer3(e.target.value);
                if(e.target.value.trim().length < 1) setIsAnswer3Correct(false)
              }
            }
            />
            <div className="correct-answer-switch">
              <FormControlLabel
                label="Correct"
                control={
                  <Tooltip title={"Correct Answer"}>
                    <Switch 
                      checked={isAnswer3Correct}
                      disabled={answer3.trim().length < 1}
                      onChange={(e) => setIsAnswer3Correct(e.target.checked)}
                    />
                  </Tooltip>
                }
              />
            </div>
          </div>
          <div className="possible-answer">
            <TextField
              className="answer-label" 
              label="Possible Answer 4"
              variant="standard"
              multiline
              value={answer4}
              onChange={(e) => {
                setAnswer4(e.target.value)
                if(e.target.value.trim().length < 1) setIsAnswer4Correct(false)
              }}
            />
            <div className="correct-answer-switch">
              <FormControlLabel
                label="Correct"
                control={
                  <Tooltip title={"Correct Answer"}>
                    <Switch
                      disabled={answer4.trim().length < 1}
                      checked={isAnswer4Correct}
                      onChange={(e) => setIsAnswer4Correct(e.target.checked)}
                    />
                  </Tooltip>
                }
              />
            </div>
          </div>
          <div className="possible-answer">
            <TextField
              className="answer-label" 
              label="Possible Answer 5"
              variant="standard"
              multiline
              value={answer5}
              onChange={(e) => {
                setAnswer5(e.target.value);
                if(e.target.value.trim().length < 1) setIsAnswer5Correct(false)
              }
            }
            />
            <div className="correct-answer-switch">
              <FormControlLabel
                label="Correct"
                control={
                  <Tooltip title={"Correct Answer"}>
                    <Switch
                      disabled={answer5.trim().length < 1}
                      checked={isAnswer5Correct}
                      onChange={(e) => setIsAnswer5Correct(e.target.checked)} />
                  </Tooltip>
                }
              />
            </div>
          </div>
          <TextField
            className="explanation"
            label="Explanation"
            multiline
            required
            variant="standard"
            value={explanation}
            onChange={(e) => setExplanation(e.target.value)}
          />
          <div className="categories-and-dropdown">
            <ListBox 
              chosenCategories={chosenCategories}
              setChosenCategories={setChosenCategories}
            />
              <TextField
                onChange={(e) => setPicture(e.target.value)}
                defaultValue={picture}
                label="Select Picture" 
                id="txt-outlined-basic"
                variant="outlined"
                className="question-picture-dropdown"
                select
                size="small"
                value={picture}
              >
              {Pictures.map((picture) => (
                <MenuItem key={picture.label} value={picture.file}>
                  {picture.label}{picturesInUse.includes(picture.file) && " (in use)"}
                </MenuItem>
              ))}
              </TextField>
          </div>
          <div className="active-question-switch">
            <FormControlLabel 
              control={
                <Tooltip title={"User profile to be active"}>
                  <Switch
                    checked={isQuestionActive}
                    onChange={(e) => setIsQuestionActive(e.target.checked)}
                  />
                </Tooltip>
              }
              label="Active"
            />
          </div>
            <div className="question-modal-btn">
            <Button 
                variant="outlined" 
                onClick={onClickCancel} 
              >
                Cancel
              </Button>
              {
                !questionToBeEdited &&
                <Button 
                  variant="outlined" 
                  onClick={() => onClickSave(false)} 
                  disabled={isSaveDisabled} 
                >
                  Save
               </Button>
              }

              <Button 
                variant="outlined" 
                onClick={onClickSave} 
                disabled={isSaveDisabled} 
              >
                Save & close
              </Button>
            </div>
            </FormGroup>
          </div>
  )
}