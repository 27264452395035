import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Tooltip, Switch, FormControlLabel } from '@mui/material';
import { useContext } from 'react';
import { QuestionsContext } from '../context/QuestionsContext';
import React from 'react';

export default function QuestionsCards({questionsToDisplay, setQuestionsToDisplay }) {
  const { updateQuestionHandler } = useContext(QuestionsContext);
  const pictureUrl = process.env.REACT_APP_IMAGE_URL;

  const handleDeactivateQuestion = (questionToBeDeactivated) => {
    updateQuestionHandler(questionToBeDeactivated.id, {active: !questionToBeDeactivated.active})

    setQuestionsToDisplay(prevQuestions => {
      const updatedQuestions = prevQuestions.map(question => {
        if (question.id === questionToBeDeactivated.id) {
          return { ...question, active: !question.active };
        } else {
          return question;
        }
      });
      return updatedQuestions;
    });
  }

  const styles = {
    media: {
      height: 0,
      paddingTop: '25%',
      marginTop:'1.5rem',
      backgroundSize: '60%',
    }
  };

  return (
    <>
      {questionsToDisplay && questionsToDisplay.map(question => (
        <Card sx={{ maxWidth: "100%" }} key={question.id} className="question-card">
          {
            question.questionPic &&
            <CardMedia className='question-picture'
              image={pictureUrl + question.questionPic}
              title="Frame"
              style={styles.media} 
            />
          }
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {question.question}
            </Typography>
            {
              question.answers.map((answer, index) => (
                <Typography variant='body1' key={index}
                  className={
                    !Array.isArray(question.correctAnswer) ?
                      question.answers[index] === question.answers[question.correctAnswer - 1] ? 'correct' : ''
                    : question.correctAnswer.map((answ, j) => (
                      answer === question.answers[answ - 1] ? 'correct' : ''
                    )).join(' ').trim()
                  }
                >
                  {answer}
                </Typography>
              ))
            }
            <div className='question-explanation'>
              <Typography 
                  variant="body1"
                  color="text.secondary"
                >
                <b>Explanation: </b>{question.explanation}
              </Typography>
            </div>
            <div className='question-categories'>
            <Typography 
              variant="body1"
              color="text.secondary"
            >
              <b>Categories: </b>
              {question.categories && question.categories.length > 0 ? question.categories.map((category, index) => (
                <React.Fragment key={category.id}>
                  {category.name}
                  {index === question.categories.length - 1 ? "" : ", "}
                </React.Fragment>
              )) : "None"}
            </Typography>
            </div>
          </CardContent>
          <CardActions className='card-actions'>
            <FormControlLabel
              control={
                <Tooltip title={question.active ? "Deactivate" : "Activate"}>
                  <Switch
                    checked={question.active}
                    onChange={(e) => handleDeactivateQuestion(question)}
                  />
                </Tooltip>
              }
              label="Active"
            />
          </CardActions>
        </Card>
      ))}
    </>
  );
}