import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useContext } from 'react';
import { CategoriesContext } from "../context/CategoriesContext";

export const QuestionsFilterDialog = ({ onClose, open, onApplyFilters, checkedCategories, setCheckedCategories }) => {
  const { categories } = useContext(CategoriesContext);


  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    const category = categories.find((category) => category.name === name);
    if (!category) {
      return;
    }
    const categoryIndex = checkedCategories.findIndex(
      (checkedCategory) => checkedCategory.id === category.id
    );
    if (categoryIndex > -1) {
      setCheckedCategories([
        ...checkedCategories.slice(0, categoryIndex),
        ...checkedCategories.slice(categoryIndex + 1),
      ]);
    } else {
      setCheckedCategories([...checkedCategories, category]);
    }
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle sx={{marginBottom: '0!important'}} >Filter questions</DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem>
          <Typography fontWeight={500} fontSize={18}>
            Categories
          </Typography>
        </ListItem>
        {categories.map((category) => (
          <ListItem key={category.id} sx={{ paddingTop: 0, paddingBottom: 0 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      checkedCategories.findIndex(
                        (checkedCategory) => checkedCategory.id === category.id
                      ) > -1
                    }
                    onChange={handleCheckboxChange}
                    name={category.name}
                  />
                }
                label={category.name}
              />
            </FormGroup>
          </ListItem>
        ))}
        <ListItem sx={{justifyContent: 'center', marginTop: '0.5rem'}}>
          <Button 
            variant="outlined" 
            onClick={onApplyFilters}
            disabled={checkedCategories.length < 1}>
            Apply
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
};